
import {defineComponent} from 'vue';
import {mqMaxWidth} from '@/utils/mq';
import ContactPopupButton from '@/components/ContactPopupButton.vue';

export default defineComponent({
    components: {ContactPopupButton},
    props: {
        contactEmailAddress: {type: String, required: true},
        contactCompletedImage: {type: String, required: false},
        instagramHandle: {type: String, required: true},
        facebookHandle: {type: String, required: true},
        linkedInHandle: {type: String, required: true},
        contactFormTermsConditionsLink: {type: String, required: true},
        contactFormTermsConditionsLinkTarget: {type: String, required: true},
        contentId: {type: String, required: true}
    },
    data() {
        return {
            isSmallScreenSize: false,
            isMobileMenuOpen: false
        };
    },
    mounted() {
        this.handleResize();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleScroll() {
            (this.$refs.navbar as Element).classList.toggle('navigation-bar--scrolled', (window.scrollY >= 80));
        },
        handleMobileMenu() {
            if (!this.isSmallScreenSize) return;
            
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
            document.body.classList.toggle('no-scroll');
        },
        handleResize() {
            return this.isSmallScreenSize = mqMaxWidth('lt').matches;
        }
    }
});
